angular.module('vdsr').controller('newUserController',
   ['$scope', '$http', '$location', '$window', function($scope, $http, $location, $window){

	$scope.logoObj = $window.stw.globals.companyImages.logos.loginlogo;
	$scope.getLogoSizeConstraints = function() {
		var imageTypeObj = $scope.logoObj;
		var props = ["width", "height", "maxWidth", "maxHeight"];
		var style = {};
		angular.forEach(props, function(v) {
			if (imageTypeObj[v]) {
				var snake_case = v.replace(/([A-Z])/g, "-$1").toLowerCase();
				style[snake_case] = imageTypeObj[v];// + "px";
			}
		});
		return style;
	};

	$scope.typePassword = true;
	$scope.newPwSubmit = function(){
      $scope.processing = true;
		$http({
			method: 'post',
			url: '/new-user',
			data: {
				newpw:$scope.newpw,
				location: $location.absUrl()
			}
		}).success(function(r){
         //console.log(r);
         $scope.successMsg = true;
		 $scope.message = r;
         $scope.processing = false;
		}).error(function(e){
         //console.log(e);
			$scope.message = e; //"There was an error processing your request!";
         $scope.processing = false;
         $scope.successMsg = false;
		});
	};
   $scope.relocate = function(){
      $window.location.href = '/login';
	};
	
	$scope.togglePasswordViewable  = () => {
		$scope.typePassword = !$scope.typePassword;
	}
}]);
